import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Login from '../pages/auth/Login';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from 'src/routes/paths';
import {
  useGetFeatureFlagsQuery,
  useGetOrganizersQuery,
  useGetRolesQuery,
} from 'src/redux/api/userAPI';
import { useAppSelector } from 'src/redux/hook';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  const { pathname } = useLocation();

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const { isLoading: isLoadingFeatureFlags } = useGetFeatureFlagsQuery(
    {
      organizerId: currentOrganizer?.id,
    },
    { skip: !currentOrganizer }
  );

  const { isLoading: isLoadingOrganizers } = useGetOrganizersQuery(user?.id, {
    skip: !user,
  });

  const { isLoading: isLoadingRoles } = useGetRolesQuery(
    {
      userId: user?.id,
      organizerId: currentOrganizer?.id,
    },
    { skip: !user || !currentOrganizer, refetchOnMountOrArgChange: true }
  );

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized || isLoadingFeatureFlags || isLoadingOrganizers || isLoadingRoles) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (
    !user?.isEmailConfirmed &&
    pathname !== PATH_DASHBOARD.home &&
    pathname !== PATH_DASHBOARD.general.offer &&
    pathname !== PATH_DASHBOARD.general.recommendation
  ) {
    return <Navigate to={PATH_DASHBOARD.home} />;
  }

  if (user?.isEmailConfirmed && requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
